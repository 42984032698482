import "./styles.css";

import Cta from "../../components/Cta";

import ios from "../../assets/ios.png";
import android from "../../assets/android.png";
import whats from "../../assets/whats.png";
import title from "../../assets/title.png";
import ios2 from "../../assets/ios2.png";
import android2 from "../../assets/android2.png";
import header3 from "../../assets/header3.png";

const Header = () => {
  return (
    <header id="header">
      <section className="header1">
        <article className="container">
          <h1 className="title">
            CATALÃO AGORA TEM <span className="green">MOBYGO</span>
          </h1>

          <p className="text">
            O aplicativo de transporte mais seguro e econômico agora em Catalão
            e adjacências.
          </p>
        </article>
      </section>

      <a
        href="http://wa.me/+5564992405731?text=Olá,%20Quero%20conhecer%20o%20App"
        target="_blank"
        rel="noreferrer"
        id="whats"
      >
        <figure>
          <img src={whats} alt="Ícone do Whatsapp" />
        </figure>
      </a>

      <section className="header2">
        <article className="container vertical">
          <h2 className="title">
            <span className="green">BAIXE O APP</span> E COMECE A ECONOMIZAR
            AGORA MESMO!
          </h2>

          <Cta img1={ios} img2={android} />
        </article>
      </section>

      <section className="header3">
        <article className="container vertical">
          <h2 className="title">PASSAGEIRO</h2>

          <figure className="title_icon">
            <img src={title} alt="Efeito" />
          </figure>

          <div className="gallery">
            <div className="left">
              <h2 className="title">
                Com a MobyGo você paga menos e anda mais!
              </h2>

              <figure className="mobile">
                <img src={header3} alt="Homem usando o App" />
              </figure>

              <p className="text">
                Por que se preocupar em abastecer o carro, enfrentar filas no
                trânsito, encontrar vaga no estacionamento – isso sem falar
                quando a vaga está longe e você ainda tem que ir andando até o
                destino final – correr riscos e chegar atrasado, se com a MobyGo
                você gasta menos tempo, menos dinheiro e ainda desembarca na
                frente de onde deseja estar.
              </p>

              <p className="text2">
                Baixe o App <b> MobyGo</b>
              </p>

              <Cta img1={android2} img2={ios2} />
            </div>

            <figure className="right">
              <img src={header3} alt="Homem usando o App" />
            </figure>
          </div>
        </article>
      </section>
    </header>
  );
};

export default Header;
