import "./styles.css";

import Cta from "../../components/Cta";
import CtaTel from "../../components/CTATel";
import CtaWpp from "../../components/CTAWpp";

import content1 from "../../assets/content1.png";
import about2 from "../../assets/about2.png";
import star from "../../assets/star.png";
import ios from "../../assets/ios.png";
import android from "../../assets/android.png";
import title from "../../assets/title.png";

const Content = () => {
  return (
    <section id="content">
      <article className="content1">
        <div className="container">
          <div className="top">
            <div className="left">
              <h2 className="title">
                Confira os benefícios de ser motorista MobyGo
              </h2>

              <p className="text">
                Como você é o seu próprio chefe, pode escolher a hora que vai
                reservar para o trabalho e aquelas em que vai se divertir com os
                amigos ou com a família. Sendo assim, dentre as principais
                vantagens de atuar como motorista de aplicativo podemos
                destacar:
              </p>

              <ul>
                <li>
                  <figure>
                    <img src={star} alt="Estrela" />
                  </figure>

                  <p>Rotina flexível</p>
                </li>

                <li>
                  <figure>
                    <img src={star} alt="Estrela" />
                  </figure>

                  <p>Horários alternativos</p>
                </li>

                <li>
                  <figure>
                    <img src={star} alt="Estrela" />
                  </figure>

                  <p>Ganhos de acordo com a produtividade</p>
                </li>

                <li>
                  <figure>
                    <img src={star} alt="Estrela" />
                  </figure>

                  <p>Dinamismo e troca de experiências</p>
                </li>

                <li>
                  <figure>
                    <img src={star} alt="Estrela" />
                  </figure>

                  <p>Retorno sobre o desempenho</p>
                </li>
              </ul>
            </div>

            <figure className="right">
              <img src={content1} alt="Homem usando o App" />
            </figure>
          </div>

          <figure className="bottom">
            <img src={about2} alt="Logo da empresa" />
          </figure>
        </div>
      </article>

      <article className="content2">
        <div className="container vertical">
          <h2 className="title">
            Aproveite agora as vantagens e seja um <br />
            <b>motorista MobyGo</b>
          </h2>

          <Cta img1={ios} img2={android} />
        </div>
      </article>

      <article className="content3">
        <div className="container vertical">
          <h2 className="title">ONDE ESTAMOS</h2>

          <figure className="title_icon">
            <img src={title} alt="Efeito" />
          </figure>

          <h2 className="text">A MobyGo chegou em Catalão - Goiás</h2>

          <p className="text2">
            Atendendo as regiões de Ipameri-go, Urutai-go, Pires do Rio,
            CaldasNovas, Corumbaiba, Nova Aurora, Goiandira, Pires Belo, Santo
            Antônio do Rio Verde, Campo Alegre, Ouvidor e Três Ranchos.
          </p>
        </div>
      </article>

      <article className="content4">
        <div className="container vertical">
          <h1 className="title">Fale conosco:</h1>

          <p className="text">Por telefone ou WhatsApp:</p>

          <div className="ctas">
            <CtaTel />
            <CtaWpp />
          </div>
        </div>
      </article>
    </section>
  );
};

export default Content;
