import { useState } from "react";
import "./styles.css";

import icon from "../../assets/iconwhats.png";

const CtaWpp = () => {
  const [text, setText] = useState("(64) 9924-05731");

  return (
    <button id="ctaWpp">
      <a
        href="http://wa.me/+5564992405731?text=Olá,%20Quero%20conhecer%20o%20App"
        target="_blank"
        rel="noreferrer"
      >
        <figure>
          <img src={icon} alt="Ícone do Whatsapp" />
        </figure>
        <p>{text}</p>
      </a>
    </button>
  );
};

export default CtaWpp;
