import "./styles.css";

import logo_rodape from "../../assets/logo_rodape.png";
import footer1 from "../../assets/footer1.png";

const Footer = () => {
  return (
    <footer id="footer">
      <div className="footer1">
        <div className="container">
          <figure className="left">
            <img src={logo_rodape} alt="Logo" />
          </figure>

          <a href="http://" target="_blank" rel="noreferrer" className="right">
            <figure>
              <img src={footer1} alt="Instagram" />
            </figure>

            <p className="text">Siga-nos no Instagram</p>
          </a>
        </div>
      </div>

      <div className="footer2">
        <div className="container">
          <p className="text">MobyGo - 2024 - Todos os direitos reservados </p>

          <p className="text2">
            Desenvolvido por{" "}
            <a href="https://magencia.com.br" target="_blank" rel="noreferrer">
              M Agência Digital
            </a>
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
