import "./styles.css";

import Cta from "../../components/Cta";

import about1_1 from "../../assets/about1_1.png";
import about1_2 from "../../assets/about1_2.png";
import about1_3 from "../../assets/about1_3.png";
import about1_4 from "../../assets/about1_4.png";
import about1_5 from "../../assets/about1_5.png";
import about1_6 from "../../assets/about1_6.png";
import about2 from "../../assets/about2.png";
import ios from "../../assets/ios.png";
import android from "../../assets/android.png";
import title from "../../assets/title.png";
import ios2 from "../../assets/ios2.png";
import android2 from "../../assets/android2.png";
import about3 from "../../assets/about3.png";

const About = () => {
  return (
    <section id="about">
      <article className="about1">
        <div className="container vertical">
          <h2 className="title">Escolha a melhor opção a qualquer momento</h2>

          <div className="gallery">
            <figure>
              <img src={about1_1} alt="Moby Day" />
            </figure>

            <figure>
              <img src={about1_2} alt="Moby Boss" />
            </figure>

            <figure>
              <img src={about1_3} alt="Moby Kids" />
            </figure>

            <figure>
              <img src={about1_4} alt="Moby Pets" />
            </figure>

            <figure>
              <img src={about1_5} alt="Moby Send" />
            </figure>

            <figure>
              <img src={about1_6} alt="Moby Mulher" />
            </figure>
          </div>

          <figure className="bottom">
            <img src={about2} alt="Logo da empresa" />
          </figure>
        </div>
      </article>

      <article className="about2">
        <div className="container vertical">
          <h2 className="title">
            Simplifique seu dia-a-dia e economize tempo e dinheiro indo de{" "}
            <b>MobyGo</b>
          </h2>

          <Cta img1={ios} img2={android} />
        </div>
      </article>

      <article className="about3">
        <div className="container vertical">
          <h2 className="title">MOTORISTA</h2>

          <figure className="title_icon">
            <img src={title} alt="Efeito" />
          </figure>

          <div className="gallery">
            <figure className="left">
              <img src={about3} alt="Homem usando o App" />
            </figure>

            <div className="right">
              <h2 className="title">
                Tenha liberdade de escolher os seus horários
              </h2>

              <p className="text">
                Seja para complementar a renda ou para ser a principal fonte de
                ganhos financeiros, tornar-se motorista de aplicativo é uma
                opção considerada por muitas pessoas. Nesse sentido, pensando em
                contribuir para uma nova forma de trabalho rentável, é que a
                MobyGo surgiu, sendo o aplicativo de mobilidade que nasceu do
                desejo de fazer do transporte urbano uma experiência justa,
                segura e diferenciada.
              </p>

              <p className="text2">
                Baixe o App MobyGo e <b>cadastre-se gratuitamente</b>
              </p>

              <Cta img1={android2} img2={ios2} />
            </div>
          </div>
        </div>
      </article>
    </section>
  );
};

export default About;
