import "./styles.css";

const Cta = (props) => {
  return (
    <section id="cta">
      <a
        href="https://itunes.apple.com/us/app/moby-go/id1439512773?l=pt&ls=1&mt=8"
        target="_blank"
        rel="noreferrer"
      >
        <figure>
          <img src={props.img1} alt="IOS" />
        </figure>
      </a>

      <a
        href="https://play.google.com/store/apps/details?id=br.moby.production"
        target="_blank"
        rel="noreferrer"
      >
        <figure>
          <img src={props.img2} alt="Android" />
        </figure>
      </a>
    </section>
  );
};

export default Cta;
